@font-face {
  font-family: "SatoshiBold";
  src: local("Satoshi"),
   url("./fonts/Satoshi/Satoshi.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "SatoshiRegular";
  src: local("Satoshi"),
   url("./fonts/Satoshi/Satoshi.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "SatoshiMedium";
  src: local("Satoshi"),
   url("./fonts/Satoshi/Satoshi.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "SatoshiLight";
  src: local("Satoshi"),
   url("./fonts/Satoshi/Satoshi.ttf") format("truetype");
  font-weight: lighter;
}

body {
  margin: 0;
  font-family: 'Overpass';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}