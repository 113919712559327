@keyframes bounce {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        transform: translate3d(0, 36px, 0);
    }
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.loadingIcon {
    width: 32px;
    height: 52px;
    background: url('../../assets/icons/bloomLoadingIcon.png');
    background-size: cover;
    animation: bounce 0.5s;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
    animation-iteration-count: infinite;
}

.Profile {
    p {
        margin: 0,
    }

    .basicInfoItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;

        .text {
            height: 17px;
            margin: 0;
            margin-left: 8px;
            font-size: 16px;
            font-weight: 500;
            color: rgba(18, 17, 32, 0.7);
        }

        .marijuanaIcon {
            width: 18px;
            height: 18px;
            background: url('../../assets/icons/marijuanaIcon.png');
            background-size: contain;
        }

        .smokingIcon {
            width: 18px;
            height: 18px;
            background: url('../../assets/icons/smokingIcon.png');
            background-size: contain;
        }
    }

    .characteristics,
    .interests {
        margin-top: 24px;
        display: flex;
        flex-direction: column;

        .blurbContainer {
            margin-top: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            gap: 16px;

            .blurb {
                background-color: #FAF8F6;
                padding: 9px 16px;
                border-radius: 9999px;
            }
        }
    }

    .topics {
        display: flex;
        flex-direction: column;
        gap: 32px;

        .title {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 12px;
            text-align: left;
        }

        .content {
            text-align: left;
            font-size: 16px;
            font-weight: 450;
            color: rgba(18, 17, 32, 0.9),
        }
    }
}

@media only screen and (min-width: 600px) and (max-width: 10000px) {
    .Profile {
        max-width: 375px
    }
}
