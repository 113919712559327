.EmailForm-actionItems {
    display: flex;
    flex-direction: row;
    margin-top: 56px;

    .appstore-button {
        margin-right: 12px;
    }
}

.EmailForm-emailInput {
    width: 257px;
    height: 49px;
    border-radius: 8px;
    border: none;
    margin-right: 12px;
    padding-left: 14px;
    font-size: 16px;

    &::placeholder {
        color: RGB(0, 0, 0, 0.45);
    }

    &:focus {
        outline: none;
        box-shadow: RGB(149, 157, 165, 0.2) 0px 4px 12px;
        -webkit-appearance: none;
    }

    &.is-error {
        border: 2px solid #F43255;
    }
}

.EmailForm-error {

}

.EmailForm-submitButton {
    height: 49px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    color: #FCFCFC;
    font-weight: 700;
    background-color: #4B9B53;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;

    &:focus {
        outline: none;
    }
}

.EmailForm-successMessage {
    margin-top: 48px;
    border-radius: 8px;
    padding: 20px;
    width: 652px;
    font-family: 'SatoshiRegular';
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: white;

    a {
        color: black;
    }

    .links {
        display: flex;
        justify-content: center;
        margin-top: 12px;

        .link {
            margin-left: 6px;
            margin-right: 6px;
        }
    }
}

@media only screen and (max-width: 600px) {
    .EmailForm-actionItems {
        display: flex;
        flex-direction: column;
        margin-top: 42px;
        align-items: center;
    }

    .EmailForm-emailInput {
        margin-right: 0;
    }

    .EmailForm-submitButton {
        align-self: center;
        width: 273px;
        margin-top: 16px;
    
        &:focus {
            outline: none;
        }
    }

    .EmailForm-successMessage {
        margin-left: 24px;
        margin-right: 24px;
        width: auto;
    }
}

