.hover-underline-animation-black {
    display: inline-block;
    position: relative;
    color: black;
}

.hover-underline-animation-green {
    display: inline-block;
    position: relative;
    color: #4B9B53;
}

.hover-underline-animation-black:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: black;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}
  
.hover-underline-animation-black:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.hover-underline-animation-green:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #4B9B53;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}
  
.hover-underline-animation-green:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.font-face-satoshi-bold {
    font-family: 'SatoshiBold';
}

.font-face-satoshi-regular {
    font-family: 'SatoshiRegular';
}

.font-face-satoshi-medium {
    font-family: 'SatoshiMedium';
}

.font-face-satoshi-light {
    font-family: 'SatoshiLight';
}

.header {
    .nav {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        list-style: none;
        flex-direction: row;
        display: flex;
        padding-left: 215px;
        padding-right: 215px;
        padding-top: 24px;
        padding-bottom: 24px;
        background-color: white;

        .bloom-logo {
            width: 36px;
            height: 36px;
            background: url('./assets/bloom_logo.png');
            background-size: cover;   
        }

        .link {
            margin-left: 30px;
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            text-decoration: none;
            color: black;
        }
    }
}

.footer {
    background-color: white;
    padding: 154px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .primary-links {
        flex-direction: row;
        display: flex;
        align-items: center;
        
        .copyright-text {
            margin-right: 24px;
        }
    }
    
    .footer-links {
        justify-content: center;
        display: flex;
        flex-direction: row;

        .social-links {
            display: flex;
            flex-direction: column;
            margin-right: 200px;
        }

        .legal-links {
            display: flex;
            flex-direction: column;
        }

        .link {
            text-decoration: none;
            color: black;
            line-height: 24px;
        }
    }
    
    .copyright-text {
        width: 240px;
        text-align: center;
        font-family: 'SatoshiLight';
        font-size: 18px;
    }
}

@media only screen and (max-width: 600px) {
    .header {
        .nav {
            padding-left: 24px;
            padding-right: 24px;

            .link {
                margin-left: 8px;
            }

            .last {
                margin-right: 8px;
            }
        }
    }

    .footer {
        padding-bottom: 44px;
        display: flex;
        flex-direction: column;
        padding-left: 24px;
        padding-right: 24px;

        .primary-links {
            flex-direction: column;
            display: flex;
            align-items: flex-start;

            .copyright-text {
                margin-right: 0;
            }

            .link {
                margin-bottom: 16px;
            }
        }
        
        .footer-links {
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            .social-links, .legal-links {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
            }

            .legal-links {
                margin-top: 60px;
            }
        }
        
        .copyright-text {
            text-align: center;
        }
    }
}