.BlogPostCard-link {
    text-decoration: none;
    color: black;
    width: 473px;

    .title {
        width: auto;
        font-family: 'SatoshiBold';
        font-size: 18px;
        text-align: left;
    }
    
    .subtitle {
        width: auto;
        font-family: 'SatoshiRegular';
        font-size: 16px;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .BlogPostCard-contents-image {
        background-repeat: no-repeat;
        background-position: center;
        height: 246px;
        border-radius: 24px;
        margin-bottom: 24px;
        background-size: cover;
    }
}

.BlogPostCard-empty {
    height: 246px;
    border-radius: 24px;
    margin-bottom: 24px;
    width: 473px;
}

@media only screen and (max-width: 600px) {
    .BlogPostCard-empty {
        display: none;
    }

    .Blog-posts .BlogPostCard-link,
    .Home-blog .BlogPostCard-link {
        width: 100%;
    }

    .BlogPostCard-link {
        width: 360px;
        margin-bottom: 64px;
    }

}