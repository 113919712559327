.Home {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Home-mainContent {
    height: calc(100vh - 80px);
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.Home-avatar-one {
    width: 212px;
    height: 212px;
    background: url('../../assets/avatars/male_avatar_1.png');
    background-size: cover;
    border-radius: 106px;
    position: absolute;
    left: 19px;
    top: 242px;
}

.Home-avatar-two {
    width: 163px;
    height: 163px;
    background: url('../../assets/avatars/female_avatar_1.png');
    background-size: cover;
    border-radius: 81px;
    width: 163px;
    height: 164px;
    position: absolute;
    left: 178px;
    top: 443px;
}

.Home-avatar-three {
    position: absolute;
    width: 153px;
    height: 153px;
    left: -9px;
    top: 531px;
    background: url('../../assets/avatars/male_avatar_2.png');
    background-size: cover;
    border-radius: 76px;
}

.Home-avatar-four {
    position: absolute;
    width: 232px;
    height: 233px;
    right: 19px;
    top: 217px;
    border-radius: 166px;
    background: url('../../assets/avatars/female_avatar_2.png');
    background-size: cover;
}

.Home-avatar-five {
    width: 155px;
    height: 153px;
    right: 178px;
    top: 454px;
    border-radius: 77px;
    background: url('../../assets/avatars/male_avatar_3.png');
    background-size: cover;
    position: absolute;
}

.Home-avatar-six {
    position: absolute;
    width: 202px;
    height: 203px;
    right: -43px;
    top: 480px;
    border-radius: 101px;
    background: url('../../assets/avatars/female_avatar_3.png');
    background-size: cover;
}

p.Home-secondaryTitle {
    font-size: 16px;
    margin-bottom: 24px;
    font-weight: 500;
}

p.Home-mainTitlePartOne {
    font-size: 64px;
    font-weight: 700;
    width: 900px;
    margin: 0;
    line-height: 85px;
}

p.Home-mainTitlePartTwo {
    font-size: 20px;
    font-weight: 400;
    margin-top: 28px;
    width: 611px;
    line-height: 26px;
    font-family: 'DM Mono';
}

.Home-actionItems {
    display: flex;
    flex-direction: row;
    margin-top: 56px;
}
  
.Home-emailInput {
    width: 257px;
    height: 49px;
    background: #F7F7F8;
    border-radius: 12px;
    border: none;
    margin-right: 12px;
    padding-left: 14px;
    font-size: 16px;

    &::placeholder {
        color: RGB(0, 0, 0, 0.45);
    }

    &:focus {
        outline: none;
        box-shadow: RGB(149, 157, 165, 0.2) 0px 4px 12px;
        -webkit-appearance: none;
    }

    &.is-error {
        border: 2px solid #F43255;
    }
}

.Home-submitButton {
    height: 49px;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    color: #FCFCFC;
    font-weight: 700;
    background-color: #4B9B53;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;

    &:focus {
        outline: none;
    }
}

.Home-section-one {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    .container {
        background-color: #FAF8F6;
        width: 100%;
        justify-content: center;
        display: flex;
        margin-left: 156px;
        margin-right: 156px;
        border-radius: 24px;
        
        .discover-screen-graphic {
            width: 265px;
            height: 480px;
            background: url('../../assets/discover_screen.png');
            background-size: cover;
        }
        
        .Home-section-one-info {
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            margin-left: 109px;
            
            .Home-section-one-info-title {
                font-style: normal;
                font-weight: bold;
                font-size: 32px;
                line-height: 40px;
                width: 500px;
                text-align: left;
            }
            
            .Home-section-one-info-subtitle {
                width: 500px;
                font-size: 20px;
                line-height: 28px;
                text-align: left;
            }
        }
    }
}

.Home-sections {
    display: flex;
    flex-direction: row;
    margin-top: 42px;
    gap: 30px;
    margin-left: 156px;
    margin-right: 156px;

    .Home-section-two, .Home-section-three {
        background-color: #FAF8F6;
        border-radius: 24px;
        padding-bottom: 99px;
        
        .header {
            padding-top: 65px;
            padding-left: 71px;
            padding-right: 71px;
            text-align: left;
        }

        .subtitle {
            margin-top: 24px;
            text-align: left;
            padding-left: 71px;
            padding-right: 71px;
            font-size: 20px;
            line-height: 28px;
        }
    }

    .Home-section-three {
        padding-bottom: 66px;
    }
    
    .curated-profiles-graphic {
        width: 100%;
        height: 209px;
        background: url('../../assets/pills.png');
        background-size: contain;
        margin-top: 107px;
    }

    .meaningful-profiles-graphic {
        width: 500px;
        height: 410px;
        background: url('../../assets/topic.png');
        background-size: cover;
        margin-top: 47px;
    }
}

.Home-section-four {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 42px;

    .container {
        background-color: #FAF8F6;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 500px;
        border-radius: 24px;
        width: 100%;
        margin-left: 156px;
        margin-right: 156px;
        padding-left: 84px;
        padding-right: 84px;

        .info {
            flex-direction: column;
            width: 100%;
    
            .header {
                font-size: 32px;
                width: 374px;
                text-align: left;
            }
    
            .subtitle {
                font-size: 18px;
                width: 468px;
                height: 100px;
                text-align: left;
            }
        }

        .graphic {
            background: url('../../assets/voice_messaging.png');
            background-size: cover;
            height: 323px;
            width: 496px;
        }
    }

    .subtitle {
        width: 87px;
    }
}

.Home-section-five {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 42px;

    .container {
        background-color: #FAF8F6;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 45px;
        padding-bottom: 45px;
        border-radius: 24px;
        width: 100%;
        margin-left: 156px;
        margin-right: 156px;
        padding-left: 84px;
        padding-right: 84px;

        .info {
            flex-direction: column;
            width: 100%;
    
            .header {
                font-size: 32px;
                width: 374px;
                text-align: left;
            }
    
            .subtitle {
                font-size: 18px;
                width: 468px;
                height: 100px;
                text-align: left;
            }
        }

        .email-section {
            .text {
                text-align: left;
            }
            .header {
                font-size: 36px;
                text-align: left;
                width: 380px;
            }
        }

        .graphic {
            background: url('../../assets/illustration_window.png');
            background-size: cover;
            height: 522px;
            width: 516px;
        }
    }
}

.Home-bloom-team-help {
    margin-top: 42px;
    margin-bottom: 42px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .graphic {
        height: 192px;
        width: 200px;
        background: url('../../assets/illustration_phone.png');
        background-size: cover;
    }

    .title {
        font-size: 32px;
        width: 556px;
        text-align: center;
    }

    .subtitle {
        font-size: 20px;
        width: 540px;
        height: 100px;
        text-align: center;
    }
}

.header {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
}

.title {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 37px;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 8px;
}

.subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    overflow: hidden;
    width: 406px;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.Home-blog-container {
    margin-top: 54px;
}

.Home-blog {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 215px;
    margin-right: 215px;
    gap: 35px;
    justify-content: center;
}

.link-text {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
}

@media only screen and (max-width: 1140px) {
    .Home-avatar-one, .Home-avatar-two, .Home-avatar-three, .Home-avatar-four, .Home-avatar-five, .Home-avatar-six {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .Home-avatar-one, .Home-avatar-two, .Home-avatar-three, .Home-avatar-four, .Home-avatar-five, .Home-avatar-six {
        display: none;
    }

    .Home-mainContent {
        height: 180px;
        margin-top: 220px;
    }

    p.Home-mainTitlePartOne {
        font-size: 28px;
        font-weight: 700;
        line-height: 32px;
        width: 334px;
    }

    p.Home-mainTitlePartTwo {
        font-size: 14px;
        font-weight: 400;
        line-height: 45px;
        width: 350px;
        margin-top: 14px;
    }

    .Home-section-one {
        flex-direction: column;
        justify-content: center;
        margin-top: 100px;

        .container {
            flex-direction: column;
            margin: auto;
            justify-content: center;
            align-items: center;
            padding-top: 38px;
            padding-bottom: 38px;
            margin-left: 24px;
            margin-right: 24px;
            width: calc(100% - 48px);

            .discover-screen-graphic {
                width: 265px;
                height: 540px;
                background: url('../../assets/discover_screen.png');
                background-size: cover;
            }

            .Home-section-one-info {
                background-color: #FAF8F6;
                border-radius: 24px;
                margin-left: 0;
    
                .Home-section-one-info-title {
                    text-align: center;
                    width: 214px;
                    margin-left: 0;
                    font-size: 20px;
                    text-align: left;
                }
        
                .Home-section-one-info-subtitle {
                    text-align: center;
                    width: 268px;
                    text-align: left;
                    font-size: 16px;
                    
                }
            }
        }
    }

    .Home-sections {
        flex-direction: column;
        gap: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 24px;
        width: calc(100% - 48px);

        .curated-profiles-graphic {
            margin-top: 40px;
            background-size: cover;
            height: 137px;
        }

        .meaningful-profiles-graphic {
            height: 270px;
            width: 342px;
        }

        .Home-section-two {
            margin: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-bottom: 67px;

            .header {
                padding-top: 36px;
                padding-left: 33px;
                padding-right: 36px;
                font-size: 20px;
                line-height: 25px;
                width: 244px;
                margin-bottom: 0;
            }

            .subtitle {
                margin-top: 16px;
                font-size: 20px;
                line-height: 28px;
                padding: 0;
                width: 268px;
            }
        }

        .Home-section-three {
            margin: 0;
            margin-top: 24px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-bottom: 8px;

            .header {
                padding-top: 36px;
                padding-left: 33px;
                padding-right: 36px;
                font-size: 20px;
                line-height: 25px;
                width: 244px;
                margin-bottom: 0;
            }

            .subtitle {
                margin-top: 16px;
                font-size: 16px;
                width: 244px;
            }
        }
    }

    .Home-section-four {
        width: calc(100% - 48px);
        
        .container {
            flex-direction: column;
            margin: 0;
            padding: 0;

            .info {
                display: flex;
                justify-content: center;
                align-items: center;

                .header {
                    padding-top: 36px;
                    padding-left: 33px;
                    padding-right: 33px;
                    margin: 0;
                    font-size: 20px;
                    width: 244px;
                    line-height: 25px;
                }

                .subtitle {
                    margin-top: 9px;
                    font-size: 16px;
                    width: 244px;
                    line-height: 23px;
                }
                
            }

            .graphic {
                height: 286px;
                width: 312px;
            }
        }
    }

    .Home-section-five {
        width: 100%;

        .container {
            width: calc(100% - 48px);
            flex-direction: column;
            margin: 0;
            padding: 0;
            padding-top: 55px;
            padding-bottom: 55px;


            .email-section {
                display: flex;
                flex-direction: column;

                .text {
                    text-align: center;
                    margin-left: 24px;
                    margin-right: 24px;
                    width: auto;
                }
                
                .header {
                    font-size: 24px;
                    line-height: 28px;
                    margin: 0;
                    margin-top: 42px;
                    text-align: center;
                    width: 273px;
                }
            }

            .graphic {
                background-size: contain;
                height: 198px;
                width: 200px;
            }
        }
    }

    .title {
        width: 100%;
        text-align: center;
    }

    .subtitle {
        padding-left: 24px;
        padding-right: 24px;
        width: auto;
        overflow: visible;
        -webkit-line-clamp: inherit;
    }

    .Home-blog {
        margin-left: 0;
        margin-right: 0;
        gap: 0;
    }

    .BlogPostCard-contents {
        .title {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    .BlogPostCard-contents-image {
        margin-left: 24px;
        margin-right: 24px;
    }

    .Home-bloom-team-help {
        .title {
            font-size: 20px;
            line-height: 25px;
            width: 326px;
        }

        .subtitle {
            width: 292px;
            font-size: 16px;
            line-height: 23px;
        }
    }
}
