.Terms {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        margin-top: 140px;
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 64px;
        text-align: center;
    }

    .text-container {
        max-width: 652px;
    }
}

@media only screen and (max-width: 600px) {
    .Terms {
        padding-left: 24px;
        padding-right: 24px;
    }
}