@keyframes scaled {
    0% {
        transform: scale(1.0);
    }
    100% {
        transform: scale(1.1);
    }
}

.loadingIcon {
    width: 32px;
    height: 52px;
    background: url('../../assets/icons/bloomLoadingIcon.png');
    background-size: cover;
    animation: bounce 0.5s;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
    animation-iteration-count: infinite;
}

.Spotlight {
    display: flex;
    margin-top: 100px;
    flex-direction: column;
    align-items: center;

    p {
        margin: 0,
    }

    .carousel {
        display: flex;
        flex-direction: row;
        gap: 16px;

        .card {
            height: 529px;
            width: 327px;
            border-radius: 12px;

            &:hover {
                animation: scaled 0.5s;
                animation-fill-mode: forwards
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .Spotlight {
        .carousel {
            flex-direction: column;
        }
    }
}


