.Support {
    padding-left: 215px;
    padding-right: 215px;

    .title {
        margin-top: 140px;
        text-align: center;
        margin-bottom: 64px;
    }
    
    .content-container {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 20px;
        grid-template-rows: 500px 400px;
        grid-template-columns: 300px 300px;
        justify-content: center;
    }

    .selected-question-container {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 600px) {
    .Support {
        padding-left: 24px;
        padding-right: 24px;

        .content-container {
            display: flex;
            flex-direction: column;
        }
    }
}