.Blog-emptyState {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 413px);
    padding-top: 160px;
}

.Blog {
    margin-top: 100px;

    .Blog-heroImageContainer {
        padding-left: 40px;
        padding-right: 40px;

        .Blog-heroImage {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            height: 504px;
            border-radius: 24px;
            margin-bottom: 24px;
        }
    }

    .Blog-title {
        font-weight: 800;
        font-size: 48px;
        line-height: 74px;
        text-align: center;
        margin-bottom: 0;
        padding-left: 24px;
        padding-right: 24px;
    }

    .Blog-authorAvatarContainer {
        display: flex;
        justify-content: center;
        
        .Blog-authorAvatar {
            margin-top: 41px;
            height: 64px;
            width: 64px;
            border-radius: 32px;
            border: 1px solid black;
    
            .Blog-authorAvatar-text {
                text-align: center;
                vertical-align: middle;
                font-weight: bold;
                font-size: 18px;
            }
        }
    }

    .Blog-authorInfo {
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 174.7%;
        text-align: center;

        .publishDate {
            font-family: 'Roboto Mono';
        }

        .publishAuthor {
            margin-bottom: 64px;
        }
    }

    .Blog-content {
        justify-content: center;
        display: flex;
        flex-direction: column;
        
        .Blog-content-text {
            width: 652px;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 25px;
            margin: auto;
            margin-bottom: 30px;
            font-family: 'SatoshiRegular';

            &.is-header {
                font-size: 22px;
                font-weight: bold;
                font-family: 'SatoshiBold';
            }
        }
    }

    .Blog-suggestions {
        margin-top: 50px;
        .Blog-suggestions-header {
            font-weight: bold;
            font-size: 36px;
            line-height: 117.5%;
            text-align: center;
        }
        
        .Blog-posts {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: 215px;
            margin-right: 215px;
            gap: 35px;
            justify-content: center;
        }
    }

}

@media only screen and (max-width: 600px) {
    .Blog {
        .Blog-title {
            font-size: 32px;
            line-height: 48px;
        }

        .Blog-heroImageContainer {
            padding: 0;
        }

        .Blog-heroImageContainer .Blog-heroImage {
            height: 300px;
            border-radius: 0;
            width: 100%;
        }

        .Blog-content {
            .Blog-content-text {
                width: auto;
                margin-left: 24px;
                margin-right: 24px;
            }
        }

        .Blog-suggestions {
            .Blog-posts {
                margin-left: 0;
                margin-right: 0;
            }
        }

    }
}
